import { graphql } from 'gatsby';

import React, { Component } from 'react';
import get from 'lodash/get';

import Layout from '../layout/Layout';
import { PageHero, PageIntroduction } from '../common/components';

import EventStepSliders from './EventStepSliders';
import EventClients from './EventClients';
import EventImageSection from './EventImageSection';

interface Props {
  data: any;
  location: any;
}

class Event extends Component<Props, any> {
  render() {
    const { data } = this.props;

    const body = get(data, 'page.data.body');

    const lang = get(data, 'page.lang', 'en-us');

    return (
      <Layout data={data} location={this.props.location}>
        {Array.isArray(body)
          && body.map((slice) => {
            switch (slice.__typename) {
              case 'PrismicEventBodyHero':
                return <PageHero key={slice.id + slice.__typename} data={slice} />;
              case 'PrismicEventBodyIntroduction':
                return <PageIntroduction key={slice.id + slice.__typename} data={slice} />;
              case 'PrismicEventBodyStepSliders':
                return (
                  <EventStepSliders key={slice.id + slice.__typename} lang={lang} data={slice} />
                );
              case 'PrismicEventBodyImageSection':
                return (
                  <EventImageSection key={slice.id + slice.__typename} lang={lang} data={slice} />
                );
              case 'PrismicEventBodyClients':
                return <EventClients key={slice.id + slice.__typename} data={slice} />;
              default:
                return null;
            }
          })}
      </Layout>
    );
  }
}

export default Event;

// Query metadata for the homepage
export const query = graphql`
  query EventQuery($slug: String!) {
    page: prismicEvent(id: { eq: $slug }) {
      ...Event
    }
  }
`;
