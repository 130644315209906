import React, { Component } from 'react';
import get from 'lodash/get';
import styled from 'styled-components';

import { BodyImageSection } from '../common/components';
import { getInternalUrl } from '../utils';

const Topmargin = styled.div`
  margin-top: 35px;
`;

interface Props {
  data: any;
  lang: string;
}

class EventImageSection extends Component<Props, any> {
  render() {
    const { data, lang } = this.props;
    const title = get(data, 'primary.title1', '');
    const description = get(data, 'primary.description', '');
    const image = get(data, 'primary.image');
    const linkText = get(data, 'primary.linkText', '');

    let linkUrl = get(data, 'primary.link.document[0].data.url', '');
    let linkTarget = get(data, 'primary.link.target', '');

    if (linkUrl) {
      linkUrl = getInternalUrl(
        get(data, 'primary.link.document[0].data.url', ''),
        lang
      );
    } else {
      linkUrl = get(data, 'primary.link.url', '');
      linkTarget = '_blank';
    }

    return (
      <div>
        <Topmargin/>
        <BodyImageSection
          title={title}
          titleLevel={2}
          description={description}
          image={image}
          displayImagePosition="left"
          readMoreUrl={linkUrl}
          readMoreText={linkText}
          readMoreLinkTarget={linkTarget}
          goldenLink={true}
        />
      </div>
    );
  }
}

export default EventImageSection;
