import React from 'react';
import get from 'lodash/get';
import styled, { withTheme } from 'styled-components';
import { StepSlider, Paragraph, Link } from '../common/components';
import { getInternalUrl } from '../utils';

const UnderLink = styled(Link)``;

const UnderLinkText = styled(Paragraph)`
  text-align: center;
`;

interface Props {
  data: any;
  theme: any;
  lang: string;
}

class EventStepSliders extends React.Component<Props, any> {
  render() {
    const { data, lang, theme } = this.props;

    const layoutType = get(
      data,
      'primary.layout',
      'primary on the left, secondary on the right'
    );
    const items: any[] = get(data, 'items', []);
    const layout =
      layoutType === 'primary on the left, secondary on the right'
        ? 'primary-left'
        : 'primary-right';

    // primary
    const primaryTitle = get(data, 'primary.primaryTitle', '');
    const primaryDescription = get(data, 'primary.primaryDescription.html', '');
    const primaryReadmoreUrl = get(
      data,
      'primary.primaryReadmoreUrl.document[0].data.url',
      false
    )
      ? getInternalUrl(
          get(data, 'primary.primaryReadmoreUrl.document[0].data.url', ''),
          lang
        )
      : get(data, 'primary.primaryReadmoreUrl.url', '');

    const primaryReadmoreUrlTarget = get(
      data,
      'primary.primaryReadmoreUrl.target',
      ''
    );

    // secondary
    const secondaryTitle = get(data, 'primary.secondaryTitle', '');
    const secondaryDescription = get(
      data,
      'primary.secondaryDescription.html',
      ''
    );
    const secondaryType = get(data, 'primary.secondaryType', 'card');
    const secondaryAdditional =
      secondaryType === 'card'
        ? get(data, 'primary.secondaryAdditionalDescription.html', '')
        : null;
    const underLinkText = get(data, 'primary.under_slider_link_text', null);
    const underLinkUrl = get(data, 'primary.under_slider_link.url', null);
    return (
      <span>
        <StepSlider
          layout={layout}
          primary={{
            title: primaryTitle,
            description: primaryDescription,
            readmoreUrl: primaryReadmoreUrl,
            readmoreUrlTarget: primaryReadmoreUrlTarget,
          }}
          secondary={{
            type: secondaryType,
            title: secondaryTitle,
            description: secondaryDescription,
            additional: secondaryAdditional,
          }}
          images={items}
        />
        {underLinkUrl && underLinkText && (
          <div>
            <UnderLink to={underLinkUrl} target="_blank">
              <UnderLinkText color={theme.color.primary.main}>
                {underLinkText}
              </UnderLinkText>
            </UnderLink>
          </div>
        )}
      </span>
    );
  }
}

export default withTheme(EventStepSliders);
