import React from 'react';
import get from 'lodash/get';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { ItemsSlider, Title, Spacer, Card } from '../common/components';
import { theme } from '../common/theme';
import Introduction from "./EventClientIntroduction";

interface Props {
  data: any;
}

const AwardTitle = styled(Title)`
  font-weight: normal !important;
  margin-bottom: 0 !important;
  text-align: center;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const AwardCard = styled(Row)`
  /* ${breakpoint('md')`
    margin-left: 10px;
    margin-right: 10px;
    height: 250px;
  `} */
  margin:0 10px;
  /* height: 180px; */
  height: 100%;
  ${breakpoint('xs')`
    margin-left: 10px;
    margin-right: 10px;
    /* height: 250px; */
  `}
  ${breakpoint('md')`
    margin-left: 10px;
    margin-right: 10px;
    /* height: 300px; */
  `}
`;

const AwardCardContent = styled(Card.Content)`
  /* padding: 20px;

  ${breakpoint('md')`
    padding: 30px;
  `} */
`;
const LogoImage = styled(Card.CoverImage)`
  /* height: 100px; */
  /* height: 200px;
  width: 200px; */
  ${breakpoint('xs')`
    height: 200px;
  `}
`;
const LogoImageContainer = styled(Card.CoverImageContainer)`
  height: 80%;
  margin: 0 auto;
`;

const ClientItem = ({ title, image, link }) => (
  <a href={link} target="_blank">
    <AwardCard type="flex" color="transparent">
      {image && (
        <LogoImageContainer xs={24}>
          <LogoImage
            fluid={get(image, 'localFile.childImageSharp.fluid')}
            alt={get(image, 'alt')}
            imgStyle={{
              'object-position': 'center',
              'object-fit': 'contain',
            }}
          />
        </LogoImageContainer>
      )}
      <Card.ContentContainer xs={24}>
        <AwardCardContent type="flex" justify="space-between">
          {title && (
            <AwardTitle level={2} color={theme.color.text.main}>
              {title}
            </AwardTitle>
          )}
        </AwardCardContent>
      </Card.ContentContainer>
    </AwardCard>
  </a>
);
const CreateItems = (items: any) => {
  const awardsItem = items.map((item, i) => {
    // const document = get(item, 'award.document[0]');
    // if (!document) return null;

    const id = get(item, 'id', i);
    const title = get(item, 'title', '');
    const link = get(item, 'link', '');
    const logoImage = get(item, 'logo');

    return <ClientItem key={id} title={title} image={logoImage} link={link} />;
  });
  return awardsItem;
};
class EventClients extends React.Component<Props, any> {
  render() {
    const { data } = this.props;
    const { items = [] } = data;
    const title = get(data, 'primary.title', '');
    const description = get(data, 'primary.description', '');

    const settings = {
      rtl: false,
      slidesToShow: Math.min(5, items.length),
      infinite: items.length > 3,
      responsive: [
        {
          // Desktop breakpoint
          breakpoint: theme.breakpoints.lg,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          // Desktop breakpoint
          breakpoint: theme.breakpoints.md,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          // Tablet breakpoint
          breakpoint: theme.breakpoints.sm,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    return (
      <div>
        <Introduction
          title={title.toUpperCase()}
          description={description}
          height={150}
        />
        {items.length > 0 && (
          <Row>
            <Col span={24}>
              <ItemsSlider
                sliderItems={CreateItems(items)}
                settings={settings}
              />
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default EventClients;
